/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.fade-in {
  animation: animation-fade-in 0.45s;
}

@keyframes animation-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
